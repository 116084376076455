@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'DB Heavent';
    font-weight: 300;
    src: url('/fonts/DBHeavent-Li.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'DB Heavent';
    font-weight: normal;
    src: url('/fonts/DBHeavent-Reg.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'DB Heavent';
    font-weight: 500;
    src: url('/fonts/DBHeavent-Med.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'DB Heavent';
    font-weight: bold;
    src: url('/fonts/DBHeavent-Bd.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'DB HeaventRounded';
    font-weight: bold;
    src: url('/fonts/DB-HeaventRounded/DB HeaventRounded Bd v3.2.ttf');
    font-display: swap;
}
html,
body {
    padding: 0;
    margin: 0;
    font-family: 'DB Heavent', 'sans-serif';
    font-weight: normal;
}
a {
    color: inherit;
    text-decoration: none;
}
* {
    box-sizing: border-box;
}
ul {
    list-style: revert;
    padding-inline-start: 40px;
}

ol {
    list-style-type: decimal;
    padding-inline-start: 40px;
}

/* ----- overwrite cookie bar ----- */
.box-preference {
    border-color: #cecece !important;
    font-size: 24px !important;
    line-height: 32px !important;
    font-weight: 500 !important;
    color: #585858 !important;
}

/* ----- overwrite cookie modal ----- */
/* desktop */
.modal-scroll {
    width: 748px !important;
    min-height: 546px !important;
    margin: 0 !important;
    top: 50% !important;
    left: 50% !important;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}
.close_btn_thick {
    content: url('/icons/x.svg') !important;
    top: 27px !important;
    right: 27px !important;
}
.privacy_prompt {
    border-radius: 16px !important;
    border: none !important;
}
.privacy_prompt_content {
    padding: 24px 24px 0px 24px !important;
}
.privacy_prompt_content h1 {
    font-size: 30px !important;
    line-height: 34px !important;
    color: #2b2b2b !important;
    margin-bottom: 16px;
}
.privacy_prompt_content p {
    font-size: 21px !important;
    line-height: 24px !important;
    color: #585858 !important;
    margin-bottom: 16px;
}

.privacy_prompt_footer {
    padding: 0px 24px 24px 24px !important;
}
.cookie-list {
    padding: 8px !important;
    margin-bottom: 16px !important;
    background-color: rgba(235, 235, 235, 0.24) !important;
}
.cookie-list div {
    align-items: center !important;
}
.cookie-list .arDown {
    align-items: center !important;
}
.cookie-list .arDown p {
    font-size: 24px !important;
    line-height: 32px !important;
    color: #585858 !important;
    font-weight: bold !important;
}
.cookie-list span {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
}
.cookie-list input[type='checkbox'].toggle + label {
    max-width: 50px !important;
    text-overflow: initial !important;
}
.cookie-list input[type='checkbox'].toggle + label:before {
    width: 20px !important;
}
.cookie-list input[type='checkbox'].toggle:checked + label:before {
    left: 112% !important;
}
.cookie-list-content {
    margin: 24px 0px 0px 0px !important;
}

/* mobile */
@media (max-width: 768px) {
    .modal-scroll {
        width: 90% !important;
    }
    .privacy_prompt_content {
        padding: 16px 16px 0px 16px !important;
    }
    .privacy_prompt_footer {
        padding: 0px 16px 16px 16px !important;
    }
    .privacy_prompt_content h1 {
        text-align: start !important;
    }
    .close_btn_thick {
        top: 22px !important;
        right: 22px !important;
    }
    .privacy_prompt_content p {
        margin-bottom: 12px;
    }
}
